import { ClubTokenInvitationPublicQuery, useClubTokenInvitationPublicQuery } from "@gql-schema";
import { getClubId, getInvitationToken } from "@utils/redirUrlHelper";
import { queryParamToString } from "@uxf_base/helpers/NextContextQueryHelper";
import { useRouter } from "next/router";

export interface RedirectData {
    tokenInvitationPublicData?: ClubTokenInvitationPublicQuery["clubTokenInvitationPublic"];
    redirUrl: string;
}

export function useGetRedirectData(skip?: boolean): RedirectData {
    const { query } = useRouter();

    const redirUrl = queryParamToString(query.redirUrl);

    const clubId = getClubId(redirUrl) ?? 0;
    const token = getInvitationToken(redirUrl) ?? "";

    const { data } = useClubTokenInvitationPublicQuery({
        variables: { clubId, token },
        skip: skip || !clubId || !token,
    });

    return {
        tokenInvitationPublicData: data?.clubTokenInvitationPublic,
        redirUrl,
    };
}
