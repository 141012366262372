import { routeToUrl } from "@app-routes";
import { useTranslation } from "@translations/use-translation";
import { Button } from "@ui/Button";
import { FieldInput } from "@ui/Field/FieldInput/FieldInput";
import { Link } from "@ui/Link";
import { ReduxFieldInput, WrappedFieldInput } from "@ui/ReduxFieldWrapper/ReduxFieldInput";
import { TextMedium } from "@ui/Text";
import { CssHelper } from "@utils/CssHelper";
import { requiredField } from "@utils/formHelper";
import { InjectedFormProps, reduxForm } from "redux-form";
import { useEmailValidator, usePasswordValidator } from "../authHelper";

type TProps = {
    readonlyEmail?: string;
    isMutationLoading?: boolean;
    isEmailHidden?: boolean;
    onSubmit: (data: ILoginForm) => Promise<void> | undefined;
    redirUrl?: string;
};

export interface ILoginForm {
    email: string;
    password: string;
}

type TLoginFormProps = TProps & InjectedFormProps<ILoginForm, TProps>;

function LoginForm(props: TLoginFormProps) {
    const { t } = useTranslation();

    const emailValidator = useEmailValidator();
    const passwordValidator = usePasswordValidator();

    return (
        <form onSubmit={props.handleSubmit} method="post">
            <div className="flex flex-col justify-stretch">
                {!props.isEmailHidden && (
                    <>
                        {props.readonlyEmail ? (
                            <FieldInput
                                id="readonly-email"
                                label="E-mail"
                                controlProps={{ mb: 20 }}
                                formElementProps={{
                                    type: "email",
                                    readOnly: true,
                                    defaultValue: props.readonlyEmail,
                                    placeholder: t("component-auth-login-form:email.placeholder"),
                                }}
                            />
                        ) : (
                            <ReduxFieldInput
                                data-testid="login-form-email"
                                type="email"
                                name="email"
                                label="E-mail"
                                component={WrappedFieldInput}
                                placeholder={t("component-auth-login-form:email.placeholder")}
                                validate={[requiredField, emailValidator]}
                                autoComplete="username email"
                                controlProps={{ mb: 20 }}
                            />
                        )}
                    </>
                )}

                <ReduxFieldInput
                    data-testid="login-form-password"
                    name="password"
                    component={WrappedFieldInput}
                    placeholder={t("component-auth-login-form:password.placeholder")}
                    type="password"
                    label={t("component-auth-login-form:password.label")}
                    validate={[passwordValidator]}
                    autoComplete="current-password"
                />

                <TextMedium mt={CssHelper.spacing(1)} textAlign="right" textColor="textMuted">
                    {t("component-auth-login-form:text")}{" "}
                    <Link
                        textColor="primary"
                        href={routeToUrl(
                            "auth-zone/forgotten-password-request",
                            props.redirUrl ? { redirUrl: props.redirUrl } : {},
                        )}
                    >
                        {t("component-auth-login-form:link")}
                    </Link>
                    .
                </TextMedium>

                <Button
                    data-testid="login-form-submit"
                    loading={props.submitting || props.isMutationLoading}
                    mb={12}
                    mt={32}
                    onClick={props.handleSubmit(props.onSubmit)}
                    size="large"
                    type="submit"
                    variant="primary"
                >
                    {t("component-auth-login-form:button")}
                </Button>
            </div>
        </form>
    );
}

const FORM_NAME = "loginForm";

export default reduxForm<ILoginForm, TProps, string>({ form: FORM_NAME })(LoginForm);
