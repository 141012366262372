import { useEmailLoginDone } from "@hooks/login/useEmailLoginDone";
import { queryParamToString } from "@uxf_base/helpers/NextContextQueryHelper";
import { useRouter } from "next/router";
import LoginForm from "./LoginForm";

type TLoginComponentProps = {
    readonlyEmail?: string;
};

function LoginComponent(props: TLoginComponentProps) {
    const router = useRouter();

    const redirUrl = queryParamToString(router.query.redirUrl);

    const { loginFormSubmitHandler, loading: isMutationLoading } = useEmailLoginDone(props.readonlyEmail);

    return (
        <div className="flex flex-col justify-stretch">
            <LoginForm
                readonlyEmail={props.readonlyEmail}
                onSubmit={loginFormSubmitHandler}
                isMutationLoading={isMutationLoading}
                redirUrl={redirUrl}
            />
        </div>
    );
}

export default LoginComponent;
