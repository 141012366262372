import { AppleLoginButtons } from "@components/AuthZone/Login/Buttons/AppleLoginButtons";
import { GoogleLoginButtons } from "@components/AuthZone/Login/Buttons/GoogleLoginButtons";
import { AppInstance } from "@shared/utils/app-instance";
import { FlexBox } from "@ui/FlexBox";
import { Show } from "@uxf/core-react/components/show";

interface LoginSocialProps {
    redirectUrl?: string;
}

export function LoginSocial(props: LoginSocialProps) {
    return (
        <FlexBox flexDirection={["column", null, null, "row"]}>
            <GoogleLoginButtons onlyType={AppInstance.isSlovak ? "basic" : undefined} redirectUrl={props.redirectUrl} />

            <Show when={!AppInstance.isSlovak}>
                <AppleLoginButtons redirectUrl={props.redirectUrl} />
            </Show>
        </FlexBox>
    );
}
